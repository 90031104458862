;$(function(){
    
    "use strict";

    /*
    ;$(function(){
    "use strict";
    $(document).on('click', '.dot-pagination-w .pagination a', function(e, data, status, xhr){
        e.preventDefault();
        var $target = $(this).closest('.dot-pagination-w');
        //alert('AQUI XXX');
        $.get($(this).attr('href'), function(data){
            $target.html(data);
            //alert( "Load was performed." );
            //
            App.initAjax();
        });
    });

});
     */


    var modalTmpl = [
        // tabindex is required for focus
        '<div class="modal fade" role="dialog" data-backdrop="static" aria-hidden="true">',
            '<div class="modal-dialog modal-lg">',
                '<div class="modal-content">',
                    '<div class="modal-header">',
                        '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>',
                        '<h4 class="modal-title">Modal header</h4>', 
                    '</div>',
                    '<div class="modal-body">',
                        '<p>Modal body</p>',
                    '</div>',
                    '<div class="modal-footer">',
                        '<button type="button" class="btn btn-default dot-modal-save" data-dismiss="modal">Cancelar</button>',
                        '<button type="button" class="btn btn-success btn-lg dot-btn-modal-save"> Salvar </button>',
                    '</div>',
                '</div>',
            '</div>',
        '</div>'
      ].join('');


    // tratamento para paginação
    $(document).on('ajax:before', '.dot-pagination-w .pagination a[data-remote]', function(){
        App.blockUI($('body'));
    });
    $(document).on('ajax:success', '.dot-pagination-w .pagination a[data-remote]', function(e, data, status, xhr){
        var $targetResponse = $(this).closest('.dot-pagination-w');

        $targetResponse.html(xhr.responseText);

        App.unblockUI($('body'));
        App.initAjax();


        return;
    });


    // tratamento para filter remote
    $(document).on('ajax:before', 'form.dot-filter-form-remote[data-remote]', function(){
        App.blockUI($('body'));
    });
    // $(document).on('ajax:beforeSend', 'form.dot-filter-form-remote[data-remote]', function(event, xhr, settings) {
    //     $(this).find('input[type=text],textarea,select').each(function( index ) {
    //         var fieldName = $(this).attr('name'),
    //             fieldName = fieldName.match(/[^[\]]+(?=])/g)[0];
    //         console.log(fieldName);
    //         console.log($(this).attr('name') + " : " + $(this).val());
    //     });
    //     //console.log(xhr);
    //     //settings.url = 'xpto';
    // });
    $(document).on('ajax:success', 'form.dot-filter-form-remote[data-remote]', function(e, data, status, xhr){

        App.unblockUI($('body'));

        var $targetResponse = $($(this).data("dot-target-response"));
        console.log('debug: .dot-filter-form-remote[data-remote]');

        $targetResponse.html(xhr.responseText);

        App.initAjax();

        return;
    });

    

    // tratamento para reload url
    //$(document).on('ajax:before', 'form.dot-filter-form-remote[data-remote]', function(){
    //    App.blockUI($('body'));
    //});
    $(document).on('ajax:success', '.btn-dot-reload[data-remote]', function(e, data, status, xhr){

        //App.unblockUI($('body'));
        var $targetResponse = $($(this).data("dot-target-response"));

        $targetResponse.html(xhr.responseText);

        App.initAjax();

        return;
    });


    // tratamento para delete ajax
    $(document).on('ajax:success', '.btn-dot-delete[data-remote]', function(e, data, status, xhr){

        var $targetResponse = $($(this).data("dot-target-response"));

        if(!$targetResponse.length){
            alert('Atenção!\nVERIFICAR TARGET RESPONSE PARA DELETE!');
            return false;
        }

        $targetResponse.html(xhr.responseText);

        App.initAjax();

        return;
    });



    // tratamento para abrir modal
    $(document).on('ajax:success', '.btn-dot-modal[data-remote]', function(e, data, status, xhr){

        e.preventDefault();
        
        App.unblockUI($('.modal-dialog'));

        console.log('debug: .btn-dot-modal[data-remote]');

        //e.stopImmediatePropagation();

        // previne conflito com outros elementos dentro do form[data-remote]
        //if (!$(e.target).is('.btn[data-remote]')){
        //    return;
        //}

        //console.log(e.target);

        // verifica se foi passado dot-modal e se existe
        if(!$(this).data('dot-modal') || !$($(this).data('dot-modal')).length){
            alert('VERIFICAR MODAL PARA: Template JS');
            return false;
        }

        //if($(this).data('dot-modal') && $($(this).data('dot-modal')).length){
            //var $modal = $($(this).data('dot-modal')).modal();
            // off() Remove an event handler
        var $modal = $($(this).data('dot-modal')).off(),
            isExistOpen = false;

        
        //verifica se está visível e remove
        if($modal.hasClass('in')) { //Check if the modal is already showing
            isExistOpen = true;
            //if($modal.data('bs.modal').isShown) { //Check if the modal is already showing
            $modal.modal('hide');
            $modal.on('hidden.bs.modal', function () {
                isExistOpen = false;
            });
            //$('body').on('hidden.bs.modal', '.modal:not(.modal-cached)', function () {
            
            
        }
        
        $modal.find('.modal-body').html(xhr.responseText);

        // tratamento para título
        var $dotModalLabel = $modal.find('.dot-title-to-modal-label');
        $modal.find('.modal-title').html($dotModalLabel.html());
        $dotModalLabel.hide(); // tratamento no css


        // tratamento button save
        var $dotModalBtnSubmit = $modal.find('.dot-btn-submit');
        $modal.find('.modal-footer').addClass('hide');
        
        //if($dotModalBtnSubmit.exists()){
        if($dotModalBtnSubmit.length){

            //console.log('--- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---');
            //console.log('DEBUG: dotModalBtnSubmit');
            //console.log('--- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---');

            $dotModalBtnSubmit.hide();
            $modal.find('.modal-footer').removeClass('hide');

            // define action button save / To make sure a click only actions once use this "off" "unbind"
            //$modal.find('.dot-btn-modal-save').click(function(evt) {
            $modal.find('.dot-btn-modal-save').off().click(function(evt) {

                var elToBlock = $('.modal-dialog',$modal),
                    form = $modal.find('form');


                if(typeof(CKEDITOR) !== "undefined"){
                    var instanceCkeditor;
                    for (instanceCkeditor in CKEDITOR.instances) {
                        CKEDITOR.instances[instanceCkeditor].updateElement();
                    }
                }
                

                form.submit();

            });
        }
        
        if(isExistOpen){
            alert('Atualmente existe uma janela referente ao mesmo assunto.\nPor favor, tente resolver e abrir o próximo.');
            setTimeout(function(){
                $modal.modal('show');
            }, 100);
        } else {
            $modal.modal('show');
        }

        App.initAjax();


        //$modal.on('ajax:before', function(evt){

            // var currentdate = new Date();
            // //var aux = false;
            // console.log('ajax:before ' + currentdate.getHours() + ":"  
            //     + currentdate.getMinutes() + ":" 
            //     + currentdate.getSeconds());
            // console.log('--- --- --- --- --- --- --- --- --- --- --- --- ---');

            // $.each($modal, function(key, item){
            //     console.log(item);
            // });

            // console.log('--- --- --- --- --- --- --- --- --- --- --- --- ---');

            
            // evt.stopImmediatePropagation();
            // return false;
        //});


        //$modal.off().on('ajax:success', 'form[data-remote]', function(e, data, status, xhr){
        $modal.on('ajax:success', 'form[data-remote]', function(e, data, status, xhr){

            var fnCallback = $modal.find('form').data("dot-callback");
            
            console.log('debug(1): form[data-remote]');

            // previne conflito com outros elementos dentro do form[data-remote]
            if (!$(e.target).is('form[data-remote]')){
                return;
            }

            console.log('debug(2): form[data-remote]');


            var $targetResponse = $('#content');

            //console.log(targetResponse);
            //console.log(target);
            //console.log(target.find('form').data("target-response"));

            if($modal.find('form').data("dot-target-response")){
                
                $targetResponse = $($modal.find('form').data("dot-target-response"));

                //console.log('Qtd elements $targetResponse: ' + $targetResponse.length);
                //console.log($targetResponse);
            }


            // tratamento para mensagem de erro
            if(typeof data.error != 'undefined' && data.error == true){

                exibirErros($(this), data.message);

            } else {

                //console.log(xhr.responseText);
                console.log('HTML RESPONSE...');
                //console.log(xhr.responseText);
                //console.log($targetResponse);

                if(fnCallback){
                    //DotNotification.verificar();
                    //console.log(fnCallback);
                    //alert('1.AQUI CALLBACK!');
                    var fnCallback = window[fnCallback];
                    //console.log(fnCallback);
                    if (typeof fnCallback === "function"){
                        //alert('2.AQUI CALLBACK!');
                        fnCallback($modal, e, data, status, xhr);
                        return;
                    }
                    
                }

                $targetResponse.html(xhr.responseText);
                //$targetResponse.html('DEBUG RESPONSE');
                
                //$modal.modal('hide').remove();
                $modal.modal('hide');
                //$modal.remove();
                //$modal.data('modal', null);

                $modal.on('hidden.bs.modal', function () {
                    App.initAjax();
                });
            }
            
        });

        //$modal.on('ajax:error ajax:aborted:file', 'form[data-remote]', function(e, data, status, xhr){
        $modal.on('ajax:error', 'form[data-remote]', function(xhr, status, error){

            App.unblockUI($('.modal-dialog'));
            
            console.log('debug: ajax:error form[data-remote]');

            if(status.status==500){
                alert('Erro interno! Favor entrar em contato com o suporte técnico.');
                return;
            }
            //console.log(e);
            //console.log(xhr);
            //console.log(status);
            //console.log(data.responseJSON);
            //console.log($.parseJSON(data.responseText));

            //exibirErros($(this), data.responseJSON);
            //console.log(data);
            //if(typeof data.responseText !== 'undefined'){
            exibirErros($(this), $.parseJSON(status.responseText));
            //}

        });

        // $modal.on('ajax:aborted:file', 'form[data-remote]', function(elements){

        //     App.unblockUI($('.modal-dialog'));
        //     console.log(elements);
        //     return false;
        // });


        // $modal.on('hidden', function () {
        //     console.log('on: hidden');
        //     $(this).remove();
        // });
        
    });

    /**
     * 11/05/2018 - tratamento para Header: 401 Unauthorized (error: Unauthenticated)
     * @param  {[type]} xhr      [description]
     * @param  {[type]} status   [description]
     * @param  {[type]} error){                     if(status.status [description]
     * @return {[type]}          [description]
     */
    $(document).on('ajax:error', function(xhr, status, error){
        if(status.status==401){
            alert('Sessão expirou!');
            location.reload();
            return;
        }
    });




    function exibirErros(el, message)
    {
        el.find('#alert-box')
            .show()
            .find('ul')
            .html(toList(message));

        el.scrollTop(0);
        //el.animate({scrollTop: 0}, 'slow');
    }


    function toList(data){
        var converted = '',
            messages,
            i;


        //console.log(data);

        if(typeof data == 'undefined'){
            alert('Ocorreu um erro interno. Por favor, entre em contato!');
            return;
        }

        if(typeof data.message != 'undefined'){
            messages = data.message;
        } else {
            messages = data;
        }

        // verifica se é object
        if($.isPlainObject(messages)){
            $.each(messages, function(key, item){
                for (i=0; i<item.length; i++) { 
                    converted += '<li>' + item[i] + '</li>';
                }
            });

        } else {
            converted += '<li>' + messages + '</li>';
        }

        return converted;
    }
});




