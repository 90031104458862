;var DotMediaManager = function(){
    "use strict";
    var modalId = '#modalDotMediaManager',
        $modal = $(modalId),
        onlyImageMimetype = "image/x-png,image/gif,image/jpeg",
        urlBackend = {
            upload: '/adm/arquivo/upload',
            showItemFromList: '/adm/arquivo/showItemFromList',
            arquivos: '/adm/arquivos',
            filesToContent: '/adm/arquivo/files-to-content',
        };
        //$inputFileupload = $('#dotFileupload', $modal);
        //$inputFileupload = $('#dotFileupload');

    window.dotMediaManager = {};


    var handleUpload = function () {
        //var url = '{{ route('admin.arquivo.upload') }}';
        var url = urlBackend.upload;
        var elInputFile = DotMediaManager.getInputfile();

        DotMediaManager.prepareInputFile();
        

        elInputFile.fileupload({
            //disableValidation: false,
            singleFileUploads: false,
            headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
            url: url,
            dataType: 'json',
            
            done: function (e, data) {

                //console.log(data.result);

                $(data.result).each(function( key, file ) {

                    $.ajax({
                        //url: "{{ route('admin.arquivo.showItemFromList') }}",
                        url: urlBackend.showItemFromList,
                        type: "GET",
                        //dataType: 'json',
                        data: {
                            //id: data.result.id
                            id: file.id
                        },
                        success: function(response) {
                            $(".alert.alert-warning", $modal).remove();
                            $( ".arquivos-w", $modal ).prepend( response );
                        },
                        //error: function(xhr, status, error) {
                        //    alert("Atenção, não foi possível realizar o upload do arquivo!");
                        //},
                        
                    });

                });

                //$('.progress').fadeOut(300, function() {
                //    $('.bar').css('width', 0)
                //});

                setTimeout(function() {
                    $('#progress .progress-bar').css('width',0);
                }, 1500);
                
            },
            progressall: function (e, data) {
                var progress = parseInt(data.loaded / data.total * 100, 10);
                $('#progress .progress-bar').css(
                    'width',
                    progress + '%'
                );
            }
        })
        .on('fileuploadadd', function (e, data) {

            var filenum = data.files.length;
            //var numberOfFiles = $(this).fileupload('option').getNumberOfFiles();

            //alert(numberOfFiles);

            //if (filenum > maxFiles) {
            //    alert("The max number of files is "+maxFiles);
            //    return false;
            //}
        });

    };

    //var handleDisableItemFromFilesExist = function () {
    //    DotMediaManager.markDisabledFiles();
    //};



    return {

        //init: function() {
        //},

        initAjaxMediaManager: function() {
            handleUpload();

            // tratamento se for para ckeditor
            if(this.hasFromEditor())
                return;

            DotMediaManager.markDisabledFiles();
            DotMediaManager.updateFrontendSelected();
        },

        openModalFromCKEditor: function(editorName){
            //alert('Imagens para: ' + editorName);

            //return;

            this.setLimit2Select(1);
            this.setMediaType('image');
            this.setReference('ckeditor');
            this.setCkeditorName(editorName);

            var url = urlBackend.arquivos;
            url = url + '?dotFilter[mediaType]=' + this.getMediaType();

            $('.modal-body', $modal).load( url,function(){
                $modal.modal();
            });

            //App.initAjax();

            return;
        },

        openModal: function(btn){
            var $imgW = $(btn).closest('.dot-media-manager-from-content-w');

            this.dataForMediaManager($imgW);

            if(window.dotMediaManager.reloadContentModal){

                //var url = '{{route('admin.arquivos.index')}}';
                var url = urlBackend.arquivos;
                url = url + '?dotFilter[mediaType]=' + this.getMediaType();

                $('.modal-body', $modal).load( url,function(){
                    $modal.modal();
                });


            } else {

                $modal.modal();
            }

            //App.initAjax();

            return;
        },

        getInputfile: function(){

            return $('#dotFileupload',$modal);
            //return $('#dotFileupload', );
        },

        prepareInputFile: function(){
            var elInputFile = this.getInputfile();

            // tratamento para multiple upload
            if(this.getLimit2Select() === 1){

                elInputFile.removeAttr( "multiple" );
            } else {
                elInputFile.attr( "multiple" );
            }

            // tratamento para accept onlyImageMimetype
            if(this.getMediaType() == 'image' || this.getMediaType() == 'images') {
                elInputFile.attr( "accept", onlyImageMimetype );
            } else {
                elInputFile.removeAttr( "accept" );
            }
        },

        dataForMediaManager: function($imageW){
            var reloadContentModal = true,
                limite2select = $imageW.data('limite2select'),
                reference = $imageW.data('reference'),
                mediaType = $imageW.data('media_type');


            // verifica se é mesmo conteúdo mediaType para recarregar 
            if(typeof window.dotMediaManager.mediaType !== undefined && window.dotMediaManager.mediaType == mediaType){
                reloadContentModal = false;

                // verifica se é necessário resetar informações files, selected e removed
                if(window.dotMediaManager.reference != reference){
                    reloadContentModal = true;
                }
            }

            window.dotMediaManager.currently = $imageW;
            window.dotMediaManager.reloadContentModal = reloadContentModal;
            this.setLimit2Select(limite2select);
            this.setReference(reference);
            this.setMediaType(mediaType);

            // atualiza lista dos arquivos
            this.updateCollectionFiles();

            if(reloadContentModal){
                
                this.setCollectionSelected([]);
                //this.setCollectionRemoved([]);
            }
        },

        // 05/09/2018 - tratamento se for ckeditor
        hasFromEditor: function(){
            return this.getReference() == 'ckeditor';
        },

        updateFrontendSelected: function(){
            this.markSelected();
            this.allowBtnConfirmed();
        },

        markDisabledFiles: function(){

            $( modalId + " .file-item-w" ).each(function( index ) {
                var fileId = $( this ).data('id');

                if(DotMediaManager.fileCollectionExistById(fileId)){
                    $( this ).addClass('file-disabled');
                    //$( this ).find( ".thumbs" ).prop('disabled', true);
                } else {
                    $( this ).removeClass('file-disabled');
                }
            });
        },

        markSelected: function(){

            //if(this.countCollectionSelected() > 0){
                $( modalId + " .file-item-w" ).each(function( index ) {
                    var fileId = $( this ).data('id');

                    //if(this.selectedExistById(fileId)){
                    if(DotMediaManager.selectedExistById(fileId)){
                        $( this ).addClass('file-selected');
                    } else {
                        $( this ).removeClass('file-selected');
                    }
                });
            //}
        },

        getKey: function(data){
            for (var prop in data)
                if (data.propertyIsEnumerable(prop))
                  return prop;
        },

        setActionPageDataFromFileItem: function(el){
            var $elClick = $(el),
                $fileItemW = $elClick.closest('.file-item-w');

            window.dotMediaManagerItemActionPage = {
                elClick: $elClick,
                elFileItemW: $fileItemW,
                fileId: $fileItemW.data('id'),
                thumbs: $fileItemW.data('thumbs'),
                fileType: $fileItemW.data('file_type'),
                relationId: $fileItemW.data('relation_id'),

                fileUrl: $fileItemW.data('url')
            };
        },

        hasActionPageFileItemDisabled: function(){
            var $elFileItemW = this.getActionPageItemElFileItemW();

            return $elFileItemW.hasClass('file-disabled');
        },

        getActionPageItemFileId: function(){
            return window.dotMediaManagerItemActionPage.fileId;
        },
        getActionPageItemFileType: function(){
            return window.dotMediaManagerItemActionPage.fileType;
        },
        getActionPageItemElFileItemW: function(){
            return window.dotMediaManagerItemActionPage.elFileItemW;
        },

        getActionPageItemElFileUrl: function(){
            return window.dotMediaManagerItemActionPage.fileUrl;
        },

        selectFileToCkeditor(){
            var ckeditorName = this.getCkeditorName(),
                ckeditorTarget = CKEDITOR.instances[ckeditorName],
                fileUrl = this.getActionPageItemElFileUrl();
            ckeditorTarget.getSelection().getNative();



            ckeditorTarget.insertHtml('<img src="' + fileUrl + '" alt=""/>');
            ckeditorTarget.focus();
            $modal.modal('hide');
        },


        selectFileToConfirm: function(el){
            var styleSelected = 'file-selected';

            this.setActionPageDataFromFileItem(el);
            var fileId = this.getActionPageItemFileId(),
                fileType = this.getActionPageItemFileType(),
                $elFileItemW = this.getActionPageItemElFileItemW();

            // tratamento se for para ckeditor
            if(this.hasFromEditor()){
                this.selectFileToCkeditor();
                return;
            }

            if(this.hasActionPageFileItemDisabled()){
                return;
            }

            // tratamento para selecionar somente 1
            if(this.getLimit2Select() == 1 && this.countCollectionSelected() > 0 && !this.getSelectedById(fileId, true)){
                this.removeSelectedById(fileId);
            }

            // se está selecionado, deseja remover seleção
            //if($fileItemW.hasClass(styleSelected) && this.getSelectedById(fileId, true)){
            if($elFileItemW.hasClass(styleSelected) && this.getSelectedById(fileId, true)){
                //this.removeSelectedByKey(fileId);
                this.removeSelectedById(fileId);
            } else {

                this.getCollectionSelected().push({
                    id: fileId,
                    fileType: fileType
                });

                $elFileItemW.addClass( styleSelected );
            }

            //console.log(this.getCollectionSelected());

            this.updateFrontendSelected();
        },

        getSelectedById: function(id, getOnlyKey){

            getOnlyKey = typeof getOnlyKey !== 'undefined' ? getOnlyKey : false;

            if(this.countCollectionSelected() == 0)
                return false;

            for(var key in this.getCollectionSelected())
            {
                if(typeof this.getCollectionSelected()[key]['id'] !== undefined && this.getCollectionSelected()[key]['id']==id){
                    if(getOnlyKey===true){
                        return key;
                    }
                    return this.getCollectionSelected()[key];
                }
            }
            return false;
        },

        selectedExistById: function(fileId){
            return (this.getSelectedById(fileId))? true : false;
            //return (this.getCollectionSelected()[fileId] !== undefined);
        },


        // removeSelectedByKey: function(fileId){
        //     var $el = $('.file-item-w[data-id="' + fileId + '"]');

        //     $el.removeClass('file-selected');

        //     delete this.getCollectionSelected()[fileId];
        // },

        removeSelectedById: function(fileId){
            var $el = $(modalId + ' .file-item-w[data-id="' + fileId + '"]');

            $el.removeClass('file-selected');

            var key = this.getSelectedById(fileId, true);

            //console.log(key);

            //return delete this.getCollectionSelected()[key];
            //delete this.getCollectionSelected()[key];
            if(key != -1) {
                this.getCollectionSelected().splice(key, 1);
            }

            // for(var key in this.getCollectionSelected())
            // {
            //     if(typeof this.getCollectionSelected()[key]['id'] !== undefined && this.getCollectionSelected()[key]['id']==id){
            //         return delete this.getCollectionSelected()[key];
            //     }
            // }

            return false;
        },


        getLimit2Select: function(){
            if(typeof window.dotMediaManager.limite2select === undefined || window.dotMediaManager.limite2select == null){
                return false;
            }

            return window.dotMediaManager.limite2select;
        },

        setLimit2Select: function(data){
            return window.dotMediaManager.limite2select = data;
        },

        getMediaType: function(){
            if(typeof window.dotMediaManager.mediaType === undefined || window.dotMediaManager.mediaType == null){
                return false;
            }

            return window.dotMediaManager.mediaType;
        },

        setMediaType: function(data){
            return window.dotMediaManager.mediaType = data;
        },




        writeQtdSelected: function(){
            var $el = $("#qtdSelected");
            $el.text( this.countCollectionSelected() );
        },
        allowBtnConfirmed: function(){
            var btnDisabled = true;
            if(this.countCollectionSelected() > 0){
                btnDisabled = false;
            }

            this.writeQtdSelected();

            $('#btnConfirmed').prop("disabled", btnDisabled);
        },
        countCollectionSelected: function(){
            if(typeof this.getCollectionSelected() === undefined){
                return 0;
            }

            return Object.keys(this.getCollectionSelected()).length;
        },
        getCollectionSelected: function(){
            return window.dotMediaManager.selected;
        },

        setCollectionSelected: function(data){
            return window.dotMediaManager.selected = data;
        },

        resetCollectionSelected: function(){
            this.setCollectionSelected([]);
            this.updateFrontendSelected();
        },

        getCollectionFiles: function(){
            return window.dotMediaManager.files;
        },
        setCollectionFiles: function(data){
            return window.dotMediaManager.files = data;
        },
        countCollectionFiles: function(){
            if(typeof this.getCollectionFiles() === undefined){
                return 0;
            }
            return Object.keys(this.getCollectionFiles()).length;
        },
        updateCollectionFiles: function(){
            var $currentlyW = window.dotMediaManager.currently;

            this.setCollectionFiles([]);

            $('.manager-content-to-remote .file-item-w', $currentlyW).each(function( index ) {
                var fileId = $( this ).data('id'),
                    relationId = $( this ).data('relation_id');

                DotMediaManager.getCollectionFiles().push({
                    id: fileId,
                    relationId: relationId
                });
            });
        },
        fileCollectionExistById: function(fileId){
            return (this.getFileCollectionById(fileId))? true : false;
        },
        getFileCollectionById: function(id, getOnlyKey){

            getOnlyKey = (typeof getOnlyKey !== 'undefined') ? getOnlyKey : false;

            if(this.countCollectionFiles() == 0)
                return false;

            for(var key in this.getCollectionFiles())
            {
                if(typeof this.getCollectionFiles()[key]['id'] !== undefined && this.getCollectionFiles()[key]['id']==id){
                    if(getOnlyKey===true){
                        return key;
                    }
                    return this.getCollectionFiles()[key];
                }
            }
            return false;
        },

        getCollectionRemoved: function(){
            return window.dotMediaManager.removed;
        },

        setCollectionRemoved: function(data){
            return window.dotMediaManager.removed = data;
        },

        setReference: function(data){
            return window.dotMediaManager.reference = data;
        },
        getReference: function(){
            return window.dotMediaManager.reference;
        },

        // somente para ckeditor
        setCkeditorName: function(data){
            return window.dotMediaManager.ckeditorName = data;
        },
        getCkeditorName: function(){
            return window.dotMediaManager.ckeditorName;
        },

        dataToSendConfirmed: function(){
            return {
                limite2select: this.getLimit2Select(),
                mediaType: this.getMediaType(),
                reference: this.getReference(),

                files: this.getCollectionFiles(),
                selected: this.getCollectionSelected(),
                removed: this.getCollectionRemoved()
            };
        },


        sendConfirmed: function(){
            
            //console.log(this.dataToSendConfirmed());
            //console.log(this.getCollectionSelected());
            //console.log(this.getCollectionFiles());

            //var url = "{{ route('admin.arquivo.filesToContent') }}",
            var url = urlBackend.filesToContent,
                $currentlyW = window.dotMediaManager.currently,
                $managerContentEl = $currentlyW.find('.manager-content-to-remote');

            $.ajax({
                url: url,
                type: "POST",
                dataType: "html",
                data: this.dataToSendConfirmed(),
                success: function (html) {
                    $managerContentEl.html(html);

                    DotMediaManager.resetCollectionSelected([]);
                    $modal.modal('hide');

                    // atualiza sortable
                    App.sortableForMediaManagerFromContent()

                },
                error: function (xhr, status) {
                    alert("Sorry, there was a problem!");
                },
                complete: function (xhr, status) {
                    //$('#showresults').slideDown('slow')
                }
            });
        },

        removeFileFromContent: function(el){
            var $elClick = $(el),
                $fileItemW = $elClick.closest('.file-item-w');


                if(confirm("Deseja remover a relação deste arquivo?")){
                    $fileItemW.fadeOut(300, function() {
                        $(this).remove();
                    });                    
                }
            return;
        },

        copyPathToClipboard: function(txt){
            var $temp = $("<input>");
            $("body").append($temp);
            $temp.val(txt).select();
            document.execCommand("copy");
            $temp.remove();

            alert('Copiado com sucesso');
        }
    }; // return
}();
$(function(){
    $('#modalDotMediaManager').on('shown.bs.modal', function (e) {
        DotMediaManager.initAjaxMediaManager();
    });
});